// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-novela-tsx": () => import("./../../../src/pages/products/novela.tsx" /* webpackChunkName: "component---src-pages-products-novela-tsx" */),
  "component---src-templates-pages-articles-template-tsx": () => import("./../../../src/templates/pages/articles.template.tsx" /* webpackChunkName: "component---src-templates-pages-articles-template-tsx" */),
  "component---src-templates-posts-article-template-tsx": () => import("./../../../src/templates/posts/article.template.tsx" /* webpackChunkName: "component---src-templates-posts-article-template-tsx" */),
  "component---src-templates-posts-author-template-tsx": () => import("./../../../src/templates/posts/author.template.tsx" /* webpackChunkName: "component---src-templates-posts-author-template-tsx" */)
}

