module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Narative","short_name":"Narative","start_url":"/","background_color":"#111216","theme_color":"#111216","display":"standalone","icon":"src/assets/favicon/narative-favicon-grey.svg","icons":[{"src":"/favicon/favicon.png","sizes":"512x512","type":"image/png"},{"src":"/favicon/narative-icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/narative-touch-icon-76.png","sizes":"76x76","type":"image/png"},{"src":"/favicon/narative-touch-icon-120.png","sizes":"120x120","type":"image/png"},{"src":"/favicon/narative-touch-icon-128.png","sizes":"128x192","type":"image/png"},{"src":"/favicon/narative-touch-icon-152.png","sizes":"152x152","type":"image/png"},{"src":"/favicon/narative-touch-icon-167.png","sizes":"167x167","type":"image/png"},{"src":"/favicon/narative-touch-icon-180.png","sizes":"180x180","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-118232427-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
